import { AnyAction, combineReducers, Reducer } from "redux";
import { RootState } from "./createStore";
import authReducer from "../auth/authSlice";
import { reducer as notificationReducer } from "../notifications";

export const RESET_ACTION = {
  type: "RESET",
};

const appReducer = combineReducers({
  auth: authReducer,
  notify: notificationReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "RESET") {
    state = {} as RootState;
  }
  return appReducer(state, action);
};

export default rootReducer;
