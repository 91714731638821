import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../state/createStore";
import { useDismiss } from "./hooks";
import Snackbar from "../components/Snackbar";

const Notification: React.FC = () => {
  const { notification } = useSelector((state: RootState) => state.notify);

  const dismiss = useDismiss();

  return (
    <>
      {notification ? (
        <Snackbar
          variant={notification.variant}
          message={notification.message}
          title={notification.title}
          onClose={dismiss}
        />
      ) : null}
    </>
  );
};

export default Notification;
