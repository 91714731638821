import React from "react";

import Layout from "./components/Layout";

interface Props {
  element?: any;
  props?: any;
}

const wrapPageElement: React.FC<Props> = ({ element, props }: Props) => {
  return <Layout>{element}</Layout>;
};

export default wrapPageElement;
