import { Amplify } from "aws-amplify";

const configureAmplify = (): void => {
  Amplify.Logger.LOG_LEVEL = process.env.GATSBY_AMPLIFY_LOGGER_LOG_LEVEL;

  const config = {
    Auth: {
      identityPoolId: process.env.GATSBY_AMPLIFY_AUTH_IDENTITY_POOL_ID,
      region: process.env.GATSBY_AMPLIFY_AUTH_REGION,
      userPoolId: process.env.GATSBY_AMPLIFY_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.GATSBY_AMPLIFY_AUTH_USER_POOL_CLIENT_ID,
    },
  };
  Amplify.configure(config);
};

export default configureAmplify;
