import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NotificationVariant } from "../notifications/types";
import { useDismiss } from "../notifications/hooks";
import CrossIcon from "../images/icn-cross.svg";

const autoHideDuration = {
  success: 3000,
  warning: 4000,
  error: 6000,
  info: 3000,
};

const Root = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  bottom: 5rem;
  box-shadow: 0px 5px 20px 0px #00000050;
  color: #ffffff;
  left: 0px;
  margin: 0px auto;
  padding: 12px 16px 10px 16px;
  position: fixed;
  right: 0px;
  width: 320px;
  z-index: 999;

  display: grid;
  grid-template-columns: 35px auto;
  grid-auto-flow: column;

  opacity: 1;

  &.fading {
    transition: opacity 2s;
    opacity: 0;
  }

  h6,
  p {
    font-weight: 600;
    margin: 0px;
  }

  h6 {
    font-size: 15px;
    margin-bottom: 2px;
    text-transform: capitalize;
  }

  p {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Icon = styled.img`
  height: 28px;
  margin: 0px;
  width: 28px;
`;

const Content = styled.div`
  padding-left: 6px;
  padding-top: 6px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  right: 12px;
  padding: 0px;
  position: absolute;
  top: 12px;

  img {
    height: 16px;
    width: 16px;
  }
`;

interface Props {
  variant: NotificationVariant;
  message: string;
  title?: string;
  onClose?: () => void;
}

const Snackbar: React.FC<Props> = ({ variant, message, title, onClose }) => {
  const [hide, setHide] = useState(false);
  const dismiss = useDismiss();

  const backgroundColor =
    variant === NotificationVariant.SUCCESS
      ? "#4caf50"
      : variant === NotificationVariant.ERROR
      ? "#f44336"
      : variant === NotificationVariant.WARNING
      ? "#ff9800"
      : "#2196f3";

  const icon = `/images/icn-${variant}.svg`; // FontAwesome attribution still needs to be added

  const visibilityTimeout =
    variant === NotificationVariant.SUCCESS
      ? autoHideDuration.success
      : variant === NotificationVariant.ERROR
      ? autoHideDuration.error
      : variant === NotificationVariant.WARNING
      ? autoHideDuration.warning
      : autoHideDuration.info;

  const notifier = useRef<HTMLDivElement>(null);

  let disappearTimer: number | undefined;

  const disappear = useCallback((timestamp: number) => {
    if (!disappearTimer) {
      disappearTimer = timestamp;
    }
    if (timestamp - disappearTimer >= 2000) {
      disappearTimer = undefined;
      if (notifier.current?.style.opacity == "0" && onClose) {
        onClose();
      }
    } else {
      requestAnimationFrame(disappear);
    }
  }, []);

  let timeoutTimer: number | undefined;

  const timeout = useCallback((timestamp: number) => {
    if (!timeoutTimer) {
      timeoutTimer = timestamp;
    }
    if (
      timestamp - timeoutTimer >= visibilityTimeout &&
      timeoutTimer !== undefined
    ) {
      timeoutTimer = undefined;
      disappearTimer = undefined;
      setHide(true);
      requestAnimationFrame(disappear);
    } else {
      requestAnimationFrame(timeout);
    }
  }, []);

  const reset = useCallback(() => {
    timeoutTimer = undefined;
    disappearTimer = undefined;
    setHide(false);
  }, [variant]);

  useEffect(() => {
    reset();
    requestAnimationFrame(timeout);
  }, [variant]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dismiss();
    }, 8000);
    return () => clearTimeout(timer);
  }, [variant]);

  if (variant == "danger") {
    title = "error";
  }

  return (
    <Root
      ref={notifier}
      className={hide ? "fading" : ""}
      backgroundColor={backgroundColor}
    >
      <Icon src={icon} />
      <Content>
        <h6>{title || variant}</h6>
        <p>{message}</p>
      </Content>
      <CloseButton onClick={onClose}>
        <img src={CrossIcon} />
      </CloseButton>
    </Root>
  );
};

export default Snackbar;
