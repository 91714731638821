export enum NotificationVariant {
  SUCCESS = "success",
  ERROR = "danger",
  INFO = "info",
  WARNING = "warning",
}

export type Notification = {
  variant: NotificationVariant;
  message: string;
  title?: string;
};
