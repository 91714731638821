import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { store } from "./state/createStore";
import { LocalizationProvider } from "@mui/x-date-pickers";
interface Props {
  element?: any;
}

const wrapRootElement: React.FC<Props> = ({ element }: Props) => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
};

export default wrapRootElement;
