import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { dismiss, notify } from "./actions";
import { NotificationVariant } from "./types";

interface NotifyResult {
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
  info: (message: string) => void;
}

export const useNotify = (): NotifyResult => {
  const dispatch = useDispatch();

  const success = useCallback(
    (message: string, title?: string) => {
      dispatch(
        notify({
          variant: NotificationVariant.SUCCESS,
          message,
          title,
        })
      );
    },
    [dispatch]
  );

  const warning = useCallback(
    (message: string, title?: string) => {
      dispatch(
        notify({
          variant: NotificationVariant.WARNING,
          message,
          title,
        })
      );
    },
    [dispatch]
  );

  const error = useCallback(
    (message: string, title?: string) => {
      dispatch(
        notify({
          variant: NotificationVariant.ERROR,
          message,
          title,
        })
      );
    },
    [dispatch]
  );

  const info = useCallback(
    (message: string, title?: string) => {
      dispatch(
        notify({
          variant: NotificationVariant.INFO,
          message,
          title,
        })
      );
    },
    [dispatch]
  );

  return { success, warning, error, info };
};

export function useDismiss(): () => void {
  const dispatch = useDispatch();

  return () => {
    dispatch(dismiss());
  };
}
