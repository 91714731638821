import React from "react";
import { Helmet } from "react-helmet";

import CssBaseline from "@mui/material/CssBaseline";
import { Notification } from "../notifications";

interface Props {
  children?: any;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0, shrink-to-fit=no"
        />
      </Helmet>
      <CssBaseline />
      <Notification />
      {children}
    </div>
  );
};

export default Layout;
