import { createReducer } from "@reduxjs/toolkit";
import { Notification } from "./types";
import { notify, dismiss } from "./actions";

interface NotificationState {
  notification: Notification | null;
}

const notificationReducer = createReducer(
  {
    notification: null,
  } as NotificationState,
  {
    [notify.type]: (state, action) => {
      state.notification = action.payload;
    },
    [dismiss.type]: state => {
      state.notification = null;
    },
  }
);

export default notificationReducer;
